import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"
import React from "react";
import CountryLink from "../components/Layout/CountryLink";


const Button = styled.button(() => [
  `
@media (min-width: 768px) {
	max-width: 300px;
}
@media (min-width: 1024px) {
	max-width: 300px;
}
width: 100%;
margin-top: 1.25rem;
margin-left: auto;
margin-right: auto;
color: white;
background-color: #646fff;
transition: background-color 0.7s ease;
padding-top: 0.25rem;
padding-bottom: 0.25rem;

&:hover {
	background-color: #8d94f4;
}
-webkit-box-shadow: 0 0 10px 1px #646fff;
box-shadow: 0 0 10px 1px #646fff;
`,
  tw`px-8 py-3 text-base rounded-ultra `,
])

export default function Custom404() {


  return (
    <div
      css={tw`z-50 w-full flex content-center`}
      style={{ height: "calc(100vh - 200px)" }}
    >
      <div css={tw`bg-autogestiones-200 rounded mx-auto my-auto`}>
        <span>
          <h1 css={tw`font-medium text-center text-autogestiones-600 text-3xl lg:text-4xl py-4 px-6`}>
            Error 404 - La página no ha sido encontrada
          </h1>
          <p css={tw`font-light text-center text-autogestiones-800`}>
            Lo sentimos, la página que ha buscado no existe.
          </p>
          <span css={tw`w-full justify-center flex py-6`}>
            <CountryLink
              to="/"
              style={{
                textDecoration: `none`,
              }}
            >
              <Button>Volver a inicio</Button>
            </CountryLink>
          </span>
        </span>
      </div>
    </div>
  );
}