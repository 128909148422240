import { useStaticQuery, graphql, Link, GatsbyLinkProps, } from "gatsby"
import { useChat } from "../Chat/ChatContext"
import React from "react"



const CountryLink: React.FC<GatsbyLinkProps<{}>> = React.forwardRef(({ children, ...props }, ref) => {
    const { targetCountry } = useChat();

    const to = !props.to?.includes("http") && targetCountry?.iso   ? `/${targetCountry?.iso || ""}${props.to}` : props.to
    return (
        <Link {...props} to={to} ref={undefined}>
            {children}
        </Link>
    );
});

export default CountryLink;